import { lazy } from "react";

export const ArticlesFeed = lazy(() => import("./ArticlesFeed"));
export const AskExpertsCTA = lazy(() => import("./AskExpertsCTA"));
export const BrandValues = lazy(() => import("./BrandValues"));
export const Connecting = lazy(() => import("./Connecting"));
export const CommunityCTA = lazy(() => import("./CommunityCTA"));
export const SystemsCarousel = lazy(() => import("./SystemsCarousel"));
export const ExpertiseCTA = lazy(() => import("./ExpertiseCTA"));
export const GalleryCarousel = lazy(() => import("./GalleryCarousel"));
export const JobsCarousel = lazy(() => import("./JobsCarousel"));
export const LinkCards = lazy(() => import("./LinkCards"));
export const RolesFeed = lazy(() => import("./RolesFeed"));
export const SkillsetsCarousel = lazy(() => import("./SkillsetsCarousel"));
export const Statistics = lazy(() => import("./Statistics"));
export const TeamCTA = lazy(() => import("./TeamCTA"));
export const Testimonials = lazy(() => import("./Testimonials"));
export const Text = lazy(() => import("./Text"));
