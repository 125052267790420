import { Content } from "@/components/Content";
import { createTitle } from "@/functions/createTitle";
import * as additionalComponents from "./__components";

export default function Page({ content }) {
  return (
    <>
      <Content items={content} additionalComponents={additionalComponents} />
    </>
  );
}

export async function getStaticProps({}) {
  return {
    props: {
      meta: {
        title: createTitle("Home"),
      },
      content: [
        { component: "Banner", props: { className: "" } },
        { component: "Intro", props: { className: "" } },
        { component: "Experts", props: { className: "" } },
        { component: "JobsCarousel", props: { className: "gradient-vertical-white-blue" } },
        { component: "Verticals", props: { className: "" } },
        { component: "Team", props: { className: "" } },
        { component: "Recruitment", props: { className: "" } },
        { component: "CommunityCTA", props: { className: "" } },
        { component: "Connecting", props: { className: "" } },
      ],
    },
  };
}
